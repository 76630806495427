import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { validateFormFields } from '@app/forms/form.helper';
import { MfaBackupCodeRequest } from '@app/reducers/mfa/mfa.model';
import { FeatureService } from '@app/startup/feature.service';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs';

import { TooltipDirective } from '../../../../../libs/tooltip/src';
import { IconComponent } from '../../../+authenticated/shared/icon.component';
import { Features } from '../../../enums';
import { ModalFieldComponent } from '../../../forms/modal-field.component';
import { MfaService } from '../../../reducers/mfa/mfa.service';
import { LaddaComponent } from '../../ladda/ladda.component';

@Component({
  selector: 'configure-backup-code',
  templateUrl: './configure-backup-code.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    LaddaComponent,
    TooltipDirective,
    ReactiveFormsModule,
    IconComponent,
    ModalFieldComponent,
  ],
})
export class ConfigureBackupCodeComponent implements OnInit {
  @Input()
  public form: UntypedFormGroup;

  @Input()
  public backupCode: string;

  @Input()
  public isLoading: boolean;

  public Features = Features;

  public password: string;

  public constructor(
    private mfaService: MfaService,
    private featureService: FeatureService,
  ) {}

  public ngOnInit() {
    this.password = this.mfaService.password;
    if (!this.password) {
      return;
    }

    const getBackupCodeData = {
      password: this.password,
    };

    this.getBackupCode(getBackupCodeData);
  }

  public submit() {
    validateFormFields(this.form);
  }

  public refreshBackupCode() {
    if (this.featureService.isFeatureActivated(Features.TMP_REFRESH_BACKUP_MFA_CODES)) {
      this.getBackupCode({
        password: this.mfaService.password,
        refresh: true,
      });
    } else {
      void this.mfaService
        .refreshBackupCode()
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((response) => {
          this.backupCode = response.backup_code;
        });
    }
  }

  private getBackupCode(data: MfaBackupCodeRequest) {
    this.isLoading = true;

    void this.mfaService
      .getBackupCode(data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((response) => {
        this.backupCode = response.backup_code;
      });
  }
}
